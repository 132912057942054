<template>
  <div>
    <Breadcrumbs
      page_title="calendar"
      :items="breadcrumbs"
      :item_btn_add="true"
      :btn_text="'addnew'"
      @ShowAction="ShowAction"
    />
    <v-card class="mx-3 my-5" style="border-radius:16px;">
      <v-container>

        <v-row class="mt-5">
        </v-row>
        <v-sheet>
          <FullCalendar
            ref="fullCalendar"
            :options="calendarOptions"
            :weekends="true"
            :editable="true"
            :droppable="true"
            v-model="focus"
            :type="type"
          />
        </v-sheet>
      </v-container>
    </v-card>

    <Add
      :show="addDialog"
      @close="addDialog = false"
      @confirm="
        addDialog = false;
        getEvents();
      "
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import Add from "./Add.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    Add,
    FullCalendar
  },
  data() {
    return {
      // calendarPlugins: [dayGridPlugin, interactionPlugin],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        // initialView: "dayGridMonth",
        events: [],
        // headerToolbar: false,
        // buttonIcons: {
        //   prev: "chevron-left",
        //   next: "chevron-right"
        // },
        // headerToolbar: {
        //   left: "prev",
        //   center: "title",
        //   end: "next"
        // },
        buttonText: {
          today: this.$t("today"),
          prev: this.$t("back"),
          next: this.$t("next")
        },
        headerToolbar: {
          start: "prev,today,next",
          center: "",
          end: "title"
        },
        // showEvent: function(e) {
        //   alert(e);
        //   this.$router.push("/home/event/preview/" + e.event.id);
        //   this.$store.commit("addEvent", e.event);
        // },
        // header: {
        //   left: "prev,next,today",
        //   center: "title",
        //   right: "title",
        // },
        eventClick: this.handleDateClick,
      },
      permissionDialog: false,
      permissionMessage: "",
      breadcrumbs: [
        {
          //image: "house.png",
          sidebar_tilte: "home"
        },
        {
          text: "event",
          href: "/home/event"
        },
        {
          text: "calendar"
        }
      ],
      focus: "",
      type: "month",
      addDialog: false,
      month: 1
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    ShowAction(v) {
      if (v == "Add") {
        this.checkEventAdd();
      }
    },
    handleDateClick: function(e) {
      this.$router.push("/home/event/preview/" + e.event.id);
      this.$store.commit("addEvent", e.event);
    },
    async checkEventAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("event");
      if (checkPermission) {
        self.addDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add Event.";
      }
    },

    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    async getEvents() {
      let self = this;
      let checkPermission = true; 
      if (checkPermission) {
        const res = await self.$axios.get(
          `${self.web_url}Courses/GetAllCourse?id=` +
            localStorage.getItem("companyID")
        );
        self.calendarOptions.events = [];
        for (const element of res.data.data) {
          self.calendarOptions.events.push(
            {
              ...element,
              title: element.courseName,
              date: element.startContentDate.split("T")[0],
              //backgroundColor: "purple",              
              //display: "background",
              //end: res.data.data[i].startContentDate,
            });
        }
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see Event.";
      }
    }
  }
};
</script>
<style scoped>
::v-deep .v-event {
  font-size: 16px !important;
  height: 40px !important;
  padding-top: 10px !important;
}
::v-deep .fc-event-title-container {
  font-size: 16px !important;
  text-align: center !important;
  background-color:  rgb(233 243 233) !important; /**#6cc8a0 #c0ffbf*/
  border-color: rgb(233 243 233) !important; /**#c0ffbf */
}
::v-deep .fc-col-header-cell-cushion {
  font-size: 18px !important;
  color: #ffffff !important;
  text-transform: uppercase;
}
::v-deep .fc-col-header {
  background-color: #f5f6fa !important;
}
::v-deep .fc-h-event {
  border: 1px solid #ffffff !important; /**#c0ffbf */
}
::v-deep .fc-daygrid-day-number {
  font-size: 16px !important;
  color: #43425d !important;
}
/* >>>.fc-toolbar .fc-right:before {
    float: right !important;
    content: 'My' !important;
} */

/* >>> .fc .fc-toolbar {
  margin-top: -2.5rem !important;
} 16.1.2023 change*/

::v-deep .fc .fc-button-primary {
  background-color: #ffffff !important;
  color: #4d4f5c !important;
  opacity: 1;
  font-size: 14px !important;
  border: 1px solid #707070;
  border-radius: 8px;
  height: 30px !important;
}
::v-deep .fc-today-button.fc-button.fc-button-primary {
  color: #3b86ff !important;
}
/* >>> .fc-toolbar-title {
  font-size: 25px !important;
} */
</style>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
    margin-top: 1.5rem !important;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
::v-deep .fc .fc-toolbar {
  display: flex;
  justify-content: space-between !important;
  /* align-items: center !important; */
}
::v-deep .fc-toolbar-title {
  font-size: 20px !important;
  color: #424242 !important;
  /* padding-left: 20px;
  padding-right: 20px; */
}
::v-deep .fc th {
  text-align: right;
  background: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .fc th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep .fc th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep .fc-scrollgrid {
  border: none !important;
}
::v-deep .fc-scrollgrid td:last-of-type {
  border-right: none !important;
  border-bottom: none !important;
}
::v-deep .fc-h-event .fc-event-title {
  color: #4FB14E;/*#4fb14e;*/
}
/* >>> .fc-daygrid-day-frame .fc-scrollgrid-sync-inner {
  background: #c0ffbf !important;
}
>>>.fc-daygrid-day .fc-day .fc-day-wed .fc-day-past .fc-day-other {
  background: royalblue !important;
} */
</style>

<style scoped>
/* .fc .fc-daygrid-day-bg .fc-bg-event {  
  background: rgb(143, 223, 130);
  color: #4fb14e;
} 
>>> .fc .fc-button-primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  border: 1px solid #a6cc39 !important;
  border-radius: 19px;
  height: 30px !important;
  width: 30px;
}
>>> .fc .fc-button {
  padding: 0rem 0.1em;
}
>>> .fc .fc-button .fc-icon-chevron-left {
  vertical-align: unset;
  margin-left: -0.15rem;
}
>>> .fc .fc-button .fc-icon-chevron-right {
  vertical-align: unset;
}*/
</style>